exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-filters-js": () => import("./../../../src/pages/product/filters.js" /* webpackChunkName: "component---src-pages-product-filters-js" */),
  "component---src-pages-product-index-js": () => import("./../../../src/pages/product/index.js" /* webpackChunkName: "component---src-pages-product-index-js" */),
  "component---src-pages-product-mdx-frontmatter-slug-js-content-file-path-src-product-door-mat-index-mdx": () => import("./../../../src/pages/product/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/product/Door Mat/index.mdx" /* webpackChunkName: "component---src-pages-product-mdx-frontmatter-slug-js-content-file-path-src-product-door-mat-index-mdx" */),
  "component---src-pages-product-mdx-frontmatter-slug-js-content-file-path-src-product-duvets-index-mdx": () => import("./../../../src/pages/product/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/product/Duvets/index.mdx" /* webpackChunkName: "component---src-pages-product-mdx-frontmatter-slug-js-content-file-path-src-product-duvets-index-mdx" */),
  "component---src-pages-product-mdx-frontmatter-slug-js-content-file-path-src-product-pillows-index-mdx": () => import("./../../../src/pages/product/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/product/Pillows/index.mdx" /* webpackChunkName: "component---src-pages-product-mdx-frontmatter-slug-js-content-file-path-src-product-pillows-index-mdx" */),
  "component---src-pages-product-mdx-frontmatter-slug-js-content-file-path-src-product-suits-index-mdx": () => import("./../../../src/pages/product/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/src/product/Suits/index.mdx" /* webpackChunkName: "component---src-pages-product-mdx-frontmatter-slug-js-content-file-path-src-product-suits-index-mdx" */)
}

