import createCache from '@emotion/cache';
import { v4 as uuidv4 } from 'uuid';

// Use btoa() to encode the UUID into a base64 string
const nonce = btoa(uuidv4());

export default function createEmotionCache() {
  return createCache({ 
    key: 'css',
    nonce: nonce,
    prepend: true,
  });
}
