import React, { createContext, useState, useContext } from 'react';
import Proptypes from 'prop-types'

const isDev = process.env.GATSBY_NODE_ENV
const defaultValues = {
  mode: isDev === 'development' ? 'dark' : 'light',
  setMode: () => {},
  
  };
const isBrowser = typeof window !== `undefined`;

const ThemeContext = createContext(defaultValues);

export const DarkThemeProvider = React.memo(({ children }) => {
  const [mode, setMode] = useState(defaultValues.mode);

 
  const toggleDarkMode = () => {
    if (isBrowser) {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
    }
  };
    




  return (
    <ThemeContext.Provider value={{ 
        ...defaultValues,
        mode,
        setMode,
        toggleDarkMode,
      }}>
      {children}
    </ThemeContext.Provider>
  );
});

const useDarkTheme = () => useContext(ThemeContext);

ThemeContext.propTypes = {
  children: Proptypes.node.isRequired,
}

export { ThemeContext, useDarkTheme };
