import React, { createContext, useContext, useState } from "react";
import PropTypes from 'prop-types'

const defaultValues = {
selectedProducts: [],
setSelectedProducts: () => {},
selectedColor:[],
setSelectedColor: () => {},
selectedSize:[],
setSelectedSize: () => {},
quantity:1,
setQuantity: () => {},

};
const AppContext = createContext(defaultValues);


const AppProvider = ({ children }) => {
const [selectedProducts, setSelectedProducts] = useState(defaultValues.selectedProducts)
const [selectedSize, setSelectedSize] = useState('')
const [selectedColor, setSelectedColor] = useState('')
const [quantity, setQuantity] = useState(1)




  return (
    <AppContext.Provider
      value={{
        ...defaultValues,
        selectedProducts, 
        setSelectedProducts,
        selectedSize, 
        setSelectedSize,
        selectedColor, 
        setSelectedColor,
        quantity, 
        setQuantity,


      }}
    >
      {children}
    </AppContext.Provider>
  );
};


const useAppContext = () => useContext(AppContext);

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppProvider, useAppContext };

