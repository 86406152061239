import * as React from 'react';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import ThemeProvider from './src/theme';
import { DarkThemeProvider } from './src/Context/ThemeContext';
import { AppProvider } from './src/Context/AppContext';
import NoInternetConnection from './src/components/NoInternetConnection';

const cache = createCache({ key: 'css', prepend: true });

export const wrapRootElement = ({ element }) => (
  <CacheProvider value={cache}>
    <AppProvider>
      <DarkThemeProvider>
        <ThemeProvider>
          <NoInternetConnection>
            {element}
          </NoInternetConnection>
        </ThemeProvider>
      </DarkThemeProvider>
    </AppProvider>
  </CacheProvider>
);
