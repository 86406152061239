import React from "react";
import { Badge, IconButton } from "@mui/material";
import { ShoppingBag } from "@mui/icons-material";
import { useAppContext } from "../Context/AppContext.js";
import { Link } from "gatsby";

export default function ShoppingCart() {
  const { selectedProducts } = useAppContext();
  return (
    <IconButton aria-label="cart" component={Link} to='/cart'>
      <Badge
        color="primary"
        badgeContent={selectedProducts?.length || 0}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ShoppingBag
          fontSize="small"
          sx={{
            color: (theme) =>
              theme.palette.mode === "dark"
                ? theme.palette.text.primary
                : theme.palette.text.primary,
          }}
        />
      </Badge>
    </IconButton>
  );
}
