import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import MainLayout from './MainLayout'

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  textAlign: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
}));

const Message = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  fontSize: '1.2rem',
}));

const RetryButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const NoInternetConnection = (props) => {
  const [isOnline, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => setOnline(navigator.onLine);

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  const handleRetry = () => {
    if (navigator.onLine) {
      setOnline(true);
    }
  };

  if (isOnline) {
    return props.children;
  } else {
    return (
    <MainLayout>
      <Container>
        <WifiOffIcon sx={{ fontSize: 40 }} />
        <Message variant="h4">No Internet Connection</Message>
        <Message variant="body1">Please check your connection and try again.</Message>
        <RetryButton variant="contained" color="primary" onClick={handleRetry}>
          Retry
        </RetryButton>
      </Container>
      </MainLayout>
    );
  }
};

export default NoInternetConnection;
