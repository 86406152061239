import * as React from 'react';
import { Box } from '@mui/material';
import { useTheme } from "@emotion/react";
import Header from './Header';
import Footer from './Footer';
import BackToTop from './BackToTop';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MainLayout = ({children }) => {
  const theme = useTheme();
  return (
    <>
    <Header />
    <Box 
    component="main" 
    sx={{ 
      width: '100%',
      flexGrow: 1,
      p:'0px',
      height:'auto',
      overflowX:'hidden',
      overflowY:'auto',
      bgcolor: theme.palette?.background?.default,
       }}>
    {children}
    <BackToTop />
  <Footer />
  </Box>
  </>

  )
}



export default MainLayout;
