import * as React from 'react';
import {
  Box,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { Facebook, Instagram, Phone } from '@mui/icons-material';
import TwitterIcon from '@mui/icons-material/Twitter';
import Logo from './Logo';


export default function Footer() {
  return (
    <Box
      sx={{
        bgcolor: 'black',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 3,
        width:'100%',
        mt:3,
        // position:'absolute',
        // bottom:0
      }}
    >
      {/* Top Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
          gap: { xs: 4, sm: 8 },
        }}
      >
        {/* Contact Information */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: { xs: '100%', sm: '60%' },
            gap: 2,
          }}
        >
          <Typography component='span' variant="h6" gutterBottom>
            Contact Us
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Phone fontSize="small" />
            <Typography variant="body2" >
              +254 721 713 854
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Phone fontSize="small" />
            <Typography variant="body2" >
              +254 718 062 102
            </Typography>
          </Stack>
        </Box>

        {/* Company Links */}
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Company
          </Typography>
          <Link underline="none"  component="a" href="/about" color='inherit'>
            About Us
          </Link>
          <Link underline="none"  component="a" href="/" color='inherit'>
            Home
          </Link>
          {/* <Link underline="none"  component="a" href="/knowledge" color='inherit'>
            Knowledge
          </Link> */}
        </Box>

        {/* Legal Links */}
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Legal
          </Typography>
          <Link underline="none"  component="a" href="/terms" color='inherit'>
            Terms
          </Link>
          <Link underline="none"  component="a" href="/privacy-policy" color='inherit'>
            Privacy
          </Link>
          <Link underline="none"  component="a" href="/contact" color='inherit'>
            Contact
          </Link>
        </Box>
      </Box>

      {/* Bottom Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderColor: 'divider',
        }}
      >
       

        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography color='white'>Designed by</Typography>
          <Link underline="none"  component="a" href="https://enigmance.com/"  aria-label="enigmance" target="_blank" >
            <Logo />
          </Link>
        </Stack>
      </Box>

      {/* Social Media Icons */}
      <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
        <IconButton color="inherit" href="https://facebook.com"  aria-label="facebook" target="_blank">
          <Facebook fontSize="small" />
        </IconButton>
        <IconButton color="inherit" href="https://instagram.com"  aria-label="intagram" target="_blank">
          <Instagram fontSize="small" />
        </IconButton>
        <IconButton color="inherit" href="https://twitter.com"  aria-label="X" target="_blank">
          <TwitterIcon fontSize="small" />
        </IconButton>
      </Box>


     
    </Box>
  );
}
